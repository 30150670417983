import React from "react"
import styles from "./styles.module.scss"
import ueFundPL from "../../../static/images/ue/ue-fund.png"
import ueFundEN from "../../../static/images/ue/ue-fund-en.png"
import ueFundLogoPL from "../../../static/images/ue/ue-fund1.png"
import ueFundLogoEN from "../../../static/images/ue/ue-fund1-en.png"
import rpLogoPL from "../../../static/images/ue/rp.png"
import rpLogoEN from "../../../static/images/ue/rp-en.png"
import { useIntl } from "gatsby-plugin-intl"
import css from "classnames"

const DonationInfoMinimal = () => {
  const intl = useIntl()
  return <div className={css(styles.DonationInfo, styles.minimal)}>
    <div className={"container"}>
      {intl.locale === "pl" && <>
        <div className={css(styles.logos)}>
          <img src={ueFundLogoPL} className={styles.logo} alt={"Fundusze Europejskie - Inteligenty Rozwój"} />
          <img src={rpLogoPL} className={css(styles.logo, styles.shadowed)} alt={"Rzeczpospolita Polska"} />
          <img src={ueFundPL} className={styles.logo}
               alt={"Unia Europejska - Europejski Fundusz Rozwoju Regionalnego"} />
        </div>
      </>}
      {intl.locale !== "pl" && <>
        <div className={styles.logos}>
          <img src={ueFundLogoEN} className={styles.logo} alt={"European Funds - Smart Growth"} />
          <img src={rpLogoEN} className={styles.logo} alt={"Republic of Poland"} />
          <img src={ueFundEN} className={styles.logo} alt={"Eurpean Union - Eurpean Regional Development Fund"} />
        </div>
      </>}
    </div>
  </div>
}

export default DonationInfoMinimal
