import React, { useEffect } from "react"
import styles from "./styles.module.scss"
import css from "classnames"
import logo from "../../../static/images/small-logo.png"
import Navigation from "../Navigation"
// import LanguageSwitcher from "../LanguageSwitcher"
import { useWindowScroll } from "react-use"
import { Helmet } from "react-helmet"
import DonationInfoMinimal from "../DonationInfo/DonationInfoMinimal"

const STICK_HEADER_BOUNDARY = 50
const Header = () => {
  const { y } = useWindowScroll()
  const isSticky = y > STICK_HEADER_BOUNDARY

  return <header className={styles.Header}>
    <Helmet>
      <meta charSet="utf-8" />
      <title>HIBRID - ochrona na metal</title>
    </Helmet>
    <section className={styles.topBar}>
      <div className="container">
        <div className={css("row", styles.topBarContent)}>
          <div className="col-12 col-md-6">
            <a href="mailto:a.bruderek@hibrid.com.pl">
              <i className={css(styles.icon, "fa fa-envelope-o")} />
              a.bruderek@hibrid.com.pl
            </a>
          </div>

          {/*<LanguageSwitcher />*/}
        </div>
      </div>
    </section>
    <section className={css(styles.mainNav, { [styles.sticky]: isSticky })}>
      <div className="container">
        <div className={css("row", styles.mainNavContent)}>
          <div className={css("col-4", "col-lg-2 order-1", styles.topBrand)}>
            <a href="/">
              <img src={logo} alt="HIBRID- ochrona metali" className={styles.logo} />
              <img src={logo} alt="HIBRID- ochrona metali" className={styles.smallLogo} />
            </a>
          </div>
          <div className={css(styles.navWrapper, "col-12 col-lg-5 order-lg-2 order-3")}>
            <Navigation />
          </div>
          <div className="col-8 col-lg-4 order-lg-3 order-2">
            <DonationInfoMinimal />
          </div>
        </div>
      </div>
    </section>
  </header>
}

export default Header
