import React, { useEffect } from "react"
import styles from "./styles.module.scss"
import { FormattedMessage, Link } from "gatsby-plugin-intl"
import { useToggle, useWindowSize } from "react-use"
import css from "classnames"

const MOBILE_MENU_MIN_WIDTH = 992

const Navigation = () => {
  const { width } = useWindowSize()
  const [servicesVisible, toggleServices] = useToggle(false)

  useEffect(() => {
    if (width > MOBILE_MENU_MIN_WIDTH) {
      toggleServices(false)
    }
  }, [width])

  const showServices = () => {
    if (width < MOBILE_MENU_MIN_WIDTH) {
      toggleServices()
    }
  }
  return (<ul className={styles.Navigation}>
    <li><Link activeClassName={styles.active} to={"/"}><FormattedMessage id={"menu.home"} /></Link></li>
    <li onClick={showServices}><Link activeClassName={styles.active} to="#"><FormattedMessage
      id={"menu.services"} /></Link>
      <ul className={css({ [styles.visible]: servicesVisible })}>
        <li><Link activeClassName={styles.active} to="/lakiernictwo"><FormattedMessage id={"menu.services.painting"} /></Link>
        </li>
        <li><Link activeClassName={styles.active} to="/magazynowanie"><FormattedMessage
          id={"menu.services.logistic"} /></Link></li>
        <li><Link activeClassName={styles.active} to="/doradztwo"><FormattedMessage
          id={"menu.services.consulting"} /></Link></li>
      </ul>
    </li>
    <li><Link activeClassName={styles.active} to={"/produkty"}><FormattedMessage id={"menu.products"} /></Link></li>
    <li><Link activeClassName={styles.active} to={"/referencje"}><FormattedMessage id={"menu.references"} /></Link></li>
    <li><Link activeClassName={styles.active} to={"/kontakt"}><FormattedMessage id={"menu.contact"} /></Link></li>
    <li><Link activeClassName={styles.active} to={"/rekrutacja"}><FormattedMessage id={"menu.recruitment"} /></Link></li>
  </ul>)
}

export default Navigation
